import { createAction } from 'redux-actions';

import CALL_API from '../middleware/api_key';
import { Schemas } from '../middleware/schemas';

export const Actions = {
  CITIES_REQUEST: 'glints/cities/CITIES_REQUEST',
  CITIES_SUCCESS: 'glints/cities/CITIES_SUCCESS',
  CITIES_FAILURE: 'glints/cities/CITIES_FAILURE',
  CITIES_CLEAR: 'glints/cities/CITIES_CLEAR',
};

export const fetchCitiesWithDispatch = options => ({
  type: 'fetchCities',
  [CALL_API]: {
    types: [
      Actions.CITIES_REQUEST,
      Actions.CITIES_SUCCESS,
      Actions.CITIES_FAILURE,
    ],
    endpoint: 'cities',
    schema: Schemas.CITY_ARRAY,
    options: {
      ...options,
      params: {
        ...(options ? options.params : {}),
        order: 'name ASC',
      },
    },
  },
});

export function fetchCities(options) {
  return async dispatch => {
    const { where, ...restParams } = options?.params || {};

    const params = new URLSearchParams({
      order: 'name ASC',
      ...restParams,
    });

    if (where) {
      params.append('where', JSON.stringify(where));
    }

    await dispatch({
      [CALL_API]: {
        types: [
          Actions.CITIES_REQUEST,
          Actions.CITIES_SUCCESS,
          Actions.CITIES_FAILURE,
        ],
        endpoint: `cities?${params.toString()}`,
        schema: Schemas.CITY_ARRAY,
      },
    });
  };
}

export const clearCities = createAction(Actions.CITIES_CLEAR);

import { GlintsChatChannel } from '../types/channel';
import { GlintsChatMessage } from '../types/message';

export const WSEventOperation = {
  READ_MESSAGE: 'READ_MESSAGE',
  NEW_MESSAGE: 'NEW_MESSAGE',
  UPDATE_MESSAGE: 'UPDATE_MESSAGE',
  UPDATE_CHANNEL: 'UPDATE_CHANNEL',
  CONNECTION_CHANGE: 'CONNECTION_CHANGE',

  // Local Event From HAPI
  CONNECTION_ESTABLISHED: 'CONNECTION_ESTABLISHED',
} as const;

export type WSEventOperation =
  (typeof WSEventOperation)[keyof typeof WSEventOperation];

export type ConnectionEstablishedEvent = {
  operation: typeof WSEventOperation.CONNECTION_ESTABLISHED;
};

export type MessageReadEvent = {
  operation: typeof WSEventOperation.READ_MESSAGE;
  opData: {
    messageID: string;
    channelID: string;
    userID: string;
  };
};

export type MessageCreateEvent = {
  operation: typeof WSEventOperation.NEW_MESSAGE;
  opData: {
    channelID: string;
    message: GlintsChatMessage;
    channelInfo: Omit<GlintsChatChannel, 'messageList'>;
  };
};

export type MessageUpdateEvent = {
  operation: typeof WSEventOperation.UPDATE_MESSAGE;
  opData: {
    channelID: string;
    message: GlintsChatMessage;
    channelInfo: Omit<GlintsChatChannel, 'messageList'>;
  };
};

export type ChannelUpdateEvent = {
  operation: typeof WSEventOperation.UPDATE_CHANNEL;
  opData: Omit<GlintsChatChannel, 'messageList'>;
};

export type ConnectionChangeEvent = {
  operation: typeof WSEventOperation.CONNECTION_CHANGE;
  opData: {
    connected: boolean;
  };
};

export type GlintsChatWSEvent =
  | ConnectionEstablishedEvent
  | MessageReadEvent
  | MessageCreateEvent
  | MessageUpdateEvent
  | ChannelUpdateEvent
  | ConnectionChangeEvent;

export const isGlintsChatWSEvent = (event: any): event is GlintsChatWSEvent =>
  event && typeof event === 'object' && 'operation' in event;

export type WSEventHandler = (event: GlintsChatWSEvent) => void;

import React, { Suspense } from 'react';
import { ButtonGroup, ButtonProps, Icon } from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import useIsWhatsAppIntegrationEnabled from '../../../../common/hooks/useIsWhatsAppIntegrationEnabled';
import { BulkApplicationStatusSource } from '../../../../common/interfaces/bulk-application-status-source';
import RenderBasedOnApplicationAccessLevel from '../../../../components/FullProfileAccess/RenderBasedOnApplicationAccessLevel';
import { RejectedMoveToInCommunication } from '../../../../components/MoveToInCommunication/RejectedMoveToInCommunication';
import RenderBasedOnApplicationStatusRejected from '../../../../components/MoveToInCommunication/RenderBasedOnApplicationStatusRejected';
import {
  applicationChatContactsExchangeStatusMessages,
  applicationChatCVExchangeStatusMessages,
  ApplicationChatCVRequestActionType,
  ApplicationContactsExchangeStatus,
  ApplicationCVExchangeStatus,
} from '../../constants';
import { useGetApplicationWithChannelAndMetadata } from '../../graphql';
import { buildApplicationDataForBulkUpdate } from '../../helper';
import {
  useChannelCVRequestMessage,
  useIsMobileVersion,
  useIsPopupMode,
} from '../../hooks';
import ApplicationStatusDropdown from '../ApplicationStatusDropdown';
import ChannelHeaderMenuButton from './ChannelHeaderMenuButton';
import { WhatsAppChannelHeaderMenuButton } from './WhatsAppMenuButton/WhatsAppChannelHeaderMenuButton';

type ExchangeActionProps<T> = {
  status: T;
  loading: boolean;
  onClick: () => void;
};

type ChannelHeaderDesktopMenuProps = {
  exchangeCV: ExchangeActionProps<ApplicationCVExchangeStatus>;
  exchangeContacts: ExchangeActionProps<ApplicationContactsExchangeStatus>;
};

const contactsExchangeStatusIcon = {
  [ApplicationContactsExchangeStatus.NONE]: <Icon name="ri-phone-line" />,
  [ApplicationContactsExchangeStatus.PENDING]: <Icon name="ri-timer3-line" />,
  [ApplicationContactsExchangeStatus.DONE]: <Icon name="ri-check" />,
};

const cvExchangeStatusIcon = {
  [ApplicationCVExchangeStatus.NONE]: <Icon name="ri-file-list-line" />,
  [ApplicationCVExchangeStatus.PENDING]: <Icon name="ri-timer3-line" />,
  [ApplicationCVExchangeStatus.DONE]: <Icon name="ri-check" />,
  [ApplicationCVExchangeStatus.AUTO_SHARED]: <Icon name="ri-check" />,
};

const Styled = {
  ButtonGroup: styled(ButtonGroup)<{
    isMobileVersion: boolean;
    isPopupVersion: boolean;
  }>`
    align-items: flex-start;
    justify-content: ${({ isPopupVersion }) =>
      isPopupVersion ? 'flex-start' : 'space-between'};

    ${({ isMobileVersion, isPopupVersion }) =>
      isMobileVersion
        ? {
            display: 'grid',
            gridTemplateColumns: isPopupVersion
              ? 'repeat(2, 1fr)'
              : 'repeat(3, 1fr)',
          }
        : undefined}
  `,
};

const AcceptCVButton = (props: ButtonProps) => (
  <ChannelHeaderMenuButton
    {...props}
    icon={<Icon name="ri-file-list-line" />}
    iconPosition="left"
    data-cy="exchange-cv-btn"
  >
    <FormattedMessage
      id="interactive-accept-resume"
      defaultMessage="Accept CV"
    />
  </ChannelHeaderMenuButton>
);

const ExchangeCVButton = ({
  exchangeStatus,
  ...rest
}: ButtonProps & {
  exchangeStatus: ApplicationCVExchangeStatus;
}) => (
  <ChannelHeaderMenuButton
    {...rest}
    icon={cvExchangeStatusIcon[exchangeStatus]}
    iconPosition="left"
    data-cy="exchange-cv-btn"
  >
    <FormattedMessage
      {...applicationChatCVExchangeStatusMessages[exchangeStatus]}
    />
  </ChannelHeaderMenuButton>
);

const ExchangePhoneButton = ({
  exchangeStatus,
  ...rest
}: ButtonProps & {
  exchangeStatus: ApplicationContactsExchangeStatus;
}) => (
  <ChannelHeaderMenuButton
    icon={contactsExchangeStatusIcon[exchangeStatus]}
    iconPosition="left"
    data-cy="exchange-phone-number-btn"
    {...rest}
  >
    <FormattedMessage
      {...applicationChatContactsExchangeStatusMessages[exchangeStatus]}
    />
  </ChannelHeaderMenuButton>
);

const ChannelHeaderMenus = ({
  exchangeContacts,
  exchangeCV,
}: ChannelHeaderDesktopMenuProps) => {
  const isPopupMode = useIsPopupMode();
  const isMobileVersion = useIsMobileVersion('ChannelHeaderMenus');
  const whatsAppEnabled = useIsWhatsAppIntegrationEnabled();
  const cvRequest = useChannelCVRequestMessage();
  const isCVRequestPendingEmpToAccept =
    exchangeCV.status === ApplicationCVExchangeStatus.PENDING &&
    cvRequest &&
    cvRequest.metadata.action_type !==
      ApplicationChatCVRequestActionType.CV_REQUEST_EMP;
  const { data } = useGetApplicationWithChannelAndMetadata();
  const application = data?.getApplicationById;

  return (
    <Styled.ButtonGroup
      isMobileVersion={isMobileVersion}
      isPopupVersion={isPopupMode}
    >
      <Choose>
        <When condition={whatsAppEnabled}>
          <WhatsAppChannelHeaderMenuButton />
        </When>
        <Otherwise>
          {application && (
            <RenderBasedOnApplicationStatusRejected
              applicationStatus={application.status}
              renderRestrictedComponent={() => (
                <RejectedMoveToInCommunication.Popover
                  bulkApplicationStatusSource={BulkApplicationStatusSource.CHAT}
                  preferredAlignment="left"
                  variant="view"
                  applicationDetails={buildApplicationDataForBulkUpdate(
                    application
                  )}
                  activator={
                    <ExchangePhoneButton
                      disabled={true}
                      exchangeStatus={exchangeContacts.status}
                    />
                  }
                />
              )}
            >
              <ExchangePhoneButton
                disabled={exchangeContacts.loading}
                onClick={exchangeContacts.onClick}
                exchangeStatus={exchangeContacts.status}
              />
            </RenderBasedOnApplicationStatusRejected>
          )}
        </Otherwise>
      </Choose>
      <Suspense fallback={null}>
        {application && (
          <Choose>
            <When condition={isCVRequestPendingEmpToAccept}>
              <RenderBasedOnApplicationAccessLevel
                applicationDetails={{
                  id: application.id,
                  status: application.status,
                  employerMetadata: application.accessLevel
                    ? {
                        accessLevel: application.accessLevel,
                      }
                    : undefined,
                }}
                vipCTAClickedTrackingLocation="skip"
                jobId={application.job.id}
                renderVipAccessContent={() => (
                  <AcceptCVButton disabled={true} />
                )}
              >
                <RenderBasedOnApplicationStatusRejected
                  applicationStatus={application.status}
                  renderRestrictedComponent={() => (
                    <RejectedMoveToInCommunication.Popover
                      bulkApplicationStatusSource={
                        BulkApplicationStatusSource.CHAT
                      }
                      variant="view"
                      applicationDetails={buildApplicationDataForBulkUpdate(
                        application
                      )}
                      activator={<AcceptCVButton disabled={true} />}
                      preferredAlignment="right"
                    />
                  )}
                >
                  <AcceptCVButton
                    disabled={exchangeCV.loading}
                    onClick={exchangeCV.onClick}
                  />
                </RenderBasedOnApplicationStatusRejected>
              </RenderBasedOnApplicationAccessLevel>
            </When>
            <Otherwise>
              <RenderBasedOnApplicationAccessLevel
                applicationDetails={{
                  id: application.id,
                  status: application.status,
                  employerMetadata: application.accessLevel
                    ? {
                        accessLevel: application.accessLevel,
                      }
                    : undefined,
                }}
                vipCTAClickedTrackingLocation="skip"
                jobId={application.job.id}
                renderVipAccessContent={() => (
                  <ExchangeCVButton
                    disabled={true}
                    exchangeStatus={exchangeCV.status}
                  />
                )}
              >
                <RenderBasedOnApplicationStatusRejected
                  applicationStatus={application.status}
                  renderRestrictedComponent={() => (
                    <RejectedMoveToInCommunication.Popover
                      bulkApplicationStatusSource={
                        BulkApplicationStatusSource.CHAT
                      }
                      variant="view"
                      applicationDetails={buildApplicationDataForBulkUpdate(
                        application
                      )}
                      activator={
                        <ExchangeCVButton
                          disabled={true}
                          exchangeStatus={exchangeCV.status}
                        />
                      }
                      preferredAlignment="right"
                    />
                  )}
                >
                  <ExchangeCVButton
                    disabled={exchangeCV.loading}
                    onClick={exchangeCV.onClick}
                    exchangeStatus={exchangeCV.status}
                  />
                </RenderBasedOnApplicationStatusRejected>
              </RenderBasedOnApplicationAccessLevel>
            </Otherwise>
          </Choose>
        )}
      </Suspense>

      <If condition={!isPopupMode}>
        <ApplicationStatusDropdown />
      </If>
    </Styled.ButtonGroup>
  );
};

export default ChannelHeaderMenus;

import { useCallback, useEffect, useState } from 'react';
import { apm } from '@elastic/apm-rum';
import { isNil } from 'lodash-es';
import { Logger, StreamChat as StreamChat_ } from 'stream-chat';

import config from '../config';
import useIsChatV2Enabled from './hooks/useIsChatV2Enabled';

const enum StreamGlintsChannelTypes {
  EMPLOYER_CANDIDATE = 'employer-candidate',
}

let StreamChat: any = {};

export const PipelineStatuses = {
  PENDING: 'PENDING',
  SHORTLISTED: 'SHORTLISTED',
  ASSESSMENT: 'ASSESSMENT',
  INTERVIEWING: 'INTERVIEWING',
  OFFERED: 'OFFERED',
  HIRED: 'HIRED',
  REJECTED: 'REJECTED',
};

export type CandidateDetails = {
  candidateFirstName: string;
  candidateLastName: string;
  candidateEmail: string;
  candidateName: string;
  candidateId: string;
  applicationStatus: keyof typeof PipelineStatuses;
  candidateProfilePic?: string;
};

export type JobDetails = {
  role: string;
  companyName: string;
  companyId: string;
  jobId: string;
};

const loadStreamChat = () =>
  import(
    /* webpackExports: "StreamChat" */
    /* webpackChunkName: "stream-chat-class" */ 'stream-chat'
  ).then(mod => mod.StreamChat);

export const makeCreateStreamChannelPayload = ({
  candidateDetails,
  jobDetails,
}: {
  candidateDetails: CandidateDetails;
  jobDetails: JobDetails;
}): CreateStreamChannelPayload => {
  const { candidateEmail, candidateFirstName, candidateLastName } =
    candidateDetails;
  const {
    companyName: glintsCompanyName,
    companyId: glintsCompanyId,
    jobId: glintsJobId,
  } = jobDetails;

  return {
    name: getStreamUserName({
      email: candidateEmail,
      firstName: candidateFirstName,
      lastName: candidateLastName,
    }),
    glintsChannelType: StreamGlintsChannelTypes.EMPLOYER_CANDIDATE,
    glintsCompanyName,
    glintsCompanyId,
    glintsJobId,
  };
};

export interface CreateStreamChannelPayload {
  name: string;
  glintsChannelType: StreamGlintsChannelTypes.EMPLOYER_CANDIDATE;
  glintsCompanyName: string;
  glintsCompanyId: string;
  glintsJobId: string;
}
interface StreamUser {
  id: string;
  name: string;
  glintsRole: string;
  image: string;
}

export const enum DefaultStreamChannelTypes {
  MESSAGING = 'messaging',
  LIVESTREAM = 'livestream',
  GAMING = 'gaming',
  TEAM = 'team',
  COMMERCE = 'commerce',
}

const setStreamChat = async () => {
  const streamChatClass = await loadStreamChat();
  StreamChat = streamChatClass;
};

class StreamChatLoggingErrors extends Error {
  override name: string = this.constructor.name;

  constructor(level: string, message: string) {
    super();
    this.message = `StreamChatClientError: Level ${level} - ${message}`;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

const getStreamInstance = async () => {
  if (Object.keys(StreamChat).length === 0) {
    try {
      await setStreamChat();
    } catch (error) {
      apm.captureError(
        'StreamChatClientError: Error Importing Stream Chat Library'
      );
      throw error;
    }
  }

  const instance = StreamChat.getInstance(config.MESSAGING_SERVICE_API_KEY, {
    timeout: 12000,
  });

  const chatLogger: Logger = (level, message) => {
    if (level === 'error' || level === 'warn') {
      apm.captureError(new StreamChatLoggingErrors(level, message));
    }
  };
  instance.logger = chatLogger;
  return instance;
};

export const connectStreamUser = async (
  streamUserData: StreamUser,
  token: string
) => {
  const client = await getStreamInstance();
  await client.connectUser({ ...streamUserData }, token);
};

export const disconnectStreamUser = async () => {
  const client = await getStreamInstance();
  await client.disconnectUser();
};

export const getStreamUserName = ({
  email,
  firstName,
  lastName,
}: {
  email: string;
  firstName?: null | string;
  lastName?: null | string;
}) => {
  if (!firstName && !lastName) {
    const [emailName] = email.split('@');
    if (emailName) return emailName.trim();
  }
  if (!firstName && lastName) return lastName.trim();
  if (!lastName && firstName) return firstName.trim();
  return `${firstName} ${lastName}`.trim();
};

export const useStreamClient = () => {
  const isChatV2Enabled = useIsChatV2Enabled();
  const [client, setClient] = useState<StreamChat_ | undefined>();

  const initClient = useCallback(async () => {
    const instance = await getStreamInstance();
    setClient(instance);
  }, []);

  useEffect(() => {
    // If chat v2 is enabled, we don't need to initialize the client
    if (isChatV2Enabled) return;
    initClient();
  }, [initClient, isChatV2Enabled]);

  return client;
};

export const useStreamCurrentUserTotalUnreadCount = () => {
  const client = useStreamClient();
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);
  const initialTotalUnreadCount = client?.user?.total_unread_count;

  useEffect(() => {
    if (typeof initialTotalUnreadCount === 'number') {
      setTotalUnreadCount(initialTotalUnreadCount);
    }
  }, [initialTotalUnreadCount]);

  useEffect(
    () =>
      client?.on(event => {
        if (!isNil(event.total_unread_count)) {
          setTotalUnreadCount(event.total_unread_count);
        }
      }).unsubscribe,
    [client]
  );

  return totalUnreadCount;
};

import Big from 'big.js';

import {
  EmployerPurchaseProductType,
  Job,
} from '../../common/interfaces/entities';
import { CompanyProductTrialInformationDetail } from '../../common/interfaces/product-free-trial-offer';
import { TimePeriodUnit } from '../../common/interfaces/time-period-unit';

export type CompanyCreditInventory = {
  amount: number;
  expiryDateTime: string;
};

export const PaymentService = {
  XENDIT_INVOICE: 'XENDIT_INVOICE',
  XENDIT_QR_CODE: 'XENDIT_QR_CODE',
} as const;

export type PaymentService =
  (typeof PaymentService)[keyof typeof PaymentService];

export const CreditPurchaseOrderStatus = {
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE',
  EXPIRED: 'EXPIRED',
  CANCELLED: 'CANCELLED',
} as const;

export type CreditPurchaseOrderStatus =
  (typeof CreditPurchaseOrderStatus)[keyof typeof CreditPurchaseOrderStatus];

export const CreditProductPurchaseOrderStatus = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
} as const;

export type CreditProductPurchaseOrderStatus =
  (typeof CreditProductPurchaseOrderStatus)[keyof typeof CreditProductPurchaseOrderStatus];

export type CreditPurchaseOrderQRPayment = {
  paymentService: typeof PaymentService.XENDIT_QR_CODE;
  qrCodeString: string;
  paymentUrl: null;
};

export type CreditBenefit = {
  amount: number;
};

export type ActiveFreeCreditBenefit = {
  amount: number;
  nextAllocationDate: string | null;
};

export type CreditPurchaseOrderInvoicePayment = {
  paymentService: typeof PaymentService.XENDIT_INVOICE;
  paymentUrl: string;
  qrCodeString: null;
};

export type CreditPurchaseOrder = {
  id: string;
  status: CreditPurchaseOrderStatus;
  redirectPathAfterPayment: string;
  totalCredits: number;
  createdAt: string;
  totalPayment: Big;
  paymentExpiryAt: string;
} & (CreditPurchaseOrderQRPayment | CreditPurchaseOrderInvoicePayment);

export type CreditProductPackage = {
  id: string;
  productId: string;
  productQuantity: number;
  product: {
    type: EmployerPurchaseProductType;
  };
  creditQuantity: number;
  creditQuantityAfterDiscount: number;
  creditDiscountPercentage: number | null;
  timePeriod: number | null;
  timePeriodUnit: TimePeriodUnit | null;
  expiresAt: string | null;
  pricingInfo: {
    savedAmount: Big;
    totalAmount: Big;
    pricePerTimePeriodUnit: Big;
    currency: string;
  };
};

export type CreditProductSubscriptionPackage = {
  id: string;
  totalPrice: Big;
  paymentFrequencyTimePeriod: number;
  paymentFrequencyTimePeriodUnit: TimePeriodUnit;
  activeFreeTrialOffer: {
    id: string;
    trialTimePeriod: number;
    trialTimePeriodUnit: TimePeriodUnit;
  } | null;
};

type AfterPurchaseActionLog = {
  relatedJobId: string | null;
  job: Required<Pick<Job, 'id' | 'jobBoost'>> | null;
};

export type HotJobProductVariantDetail = {
  durationCount: number;
  durationUnit: string;
};

export type ProductVariantDetail = HotJobProductVariantDetail;

export const VIPMembershipPurchaseType = {
  PURCHASED: 'PURCHASED',
  EXTENDED: 'EXTENDED',
} as const;

export type VIPMembershipPurchaseType =
  (typeof VIPMembershipPurchaseType)[keyof typeof VIPMembershipPurchaseType];

type VIPMembershipPurchaseDetails = {
  purchaseType: VIPMembershipPurchaseType;
};

export type CreditProductPurchaseOrderHotJobProduct = {
  type: typeof EmployerPurchaseProductType.HOT_JOB;
  variantDetail: ProductVariantDetail;
};

type CreditProductPurchaseOrderNonHotJobProduct = {
  type: Exclude<
    EmployerPurchaseProductType,
    typeof EmployerPurchaseProductType.HOT_JOB
  >;
  variantDetail: null;
};

type CreditProductPurchaseOrderProduct =
  | CreditProductPurchaseOrderHotJobProduct
  | CreditProductPurchaseOrderNonHotJobProduct;

export type CreditProductPurchaseOrder = {
  id: string;
  status: CreditProductPurchaseOrderStatus;
  productQuantity: number;
  productTimePeriod: number | null;
  productTimePeriodUnit: string | null;
  creditPurchaseOrder: CreditPurchaseOrder | null;
  companyProductInventory: {
    amount: number;
    expiryDateTime: string;
    trialInformationDetail: CompanyProductTrialInformationDetail;
  } | null;
  product: CreditProductPurchaseOrderProduct;
  afterPurchaseActionLog: AfterPurchaseActionLog | null;
  purchaseProductSpecificDetails: VIPMembershipPurchaseDetails | null;
  creditBenefit: CreditBenefit | null;
};

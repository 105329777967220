export const ReactQueryKeys = {
  applicationDetailsShortKey: ['applicationDetails'],
  applicationDetails: (applicationId: string) => [
    'applicationDetails',
    applicationId,
  ],
  channelByApplicationId: (applicationId: string) => [
    'channelByApplicationId',
    applicationId,
  ],
};

/* eslint-disable camelcase */

import React, { useEffect, useState } from 'react';
import { Button, Typography } from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';
import { useChannelStateContext, useMessageContext } from 'stream-chat-react';

import useGetS3FileUrl from '../../../../common/hooks/useGetS3FileUrl';
import { trackCVViewedEvent } from '../../../../common/tracking';
import { useApplicationReadStatus } from '../../../ApplicationDetails/hooks/useApplicationReadStatus';
import { getChannelApplicationMetadataV2 } from '../../helper';
import { CVRequestAction, MessagingMessageData } from '../../types';
import ResponsiveButtonGroup from '../ResponsiveButtonGroup';
import { getResumeKeyAndLabel } from './helpers';

const CandidateCV = () => {
  const { setApplicationRead } = useApplicationReadStatus();
  const getS3Url = useGetS3FileUrl();

  const [resumeUrl, setResumeUrl] = useState<string | null>(null);

  const { message } = useMessageContext();

  const { channel } = useChannelStateContext();
  const metadata = getChannelApplicationMetadataV2(channel);

  const resume = (
    (message as MessagingMessageData | undefined)
      ?.glints_application_chat as CVRequestAction
  )?.action_payload.resume;

  const resumeOriginalFileName = message.attachments?.find(
    item => item.type === 'file'
  )?.file_name as string;

  useEffect(() => {
    const getResumeUrl = async () => {
      if (resume) {
        const { label } = getResumeKeyAndLabel(resume);
        const url = await getS3Url(resume, label);
        setResumeUrl(url);
      }
    };
    getResumeUrl();
  });

  if (!resume) return null;

  const openResume = () => {
    if (resumeUrl) {
      trackCVViewedEvent({
        jobId: metadata.glints_job_id,
        companyId: metadata.glints_company_id,
        messageId: message.id,
        channelId: channel.id,
        channelMembersCount: channel.data?.member_count,
        applicationStatus: metadata.glints_application_status,
        messageSentDatetime: message.created_at as string,
        actionType: 'employer',
      });

      try {
        setApplicationRead(metadata.glints_application_id);
      } catch (error) {
        console.error(error);
      }

      window.open(resumeUrl, '_blank');
    }
  };

  return (
    <>
      <Typography variant="body1">{resumeOriginalFileName}</Typography>
      <ResponsiveButtonGroup>
        <Button onClick={openResume}>
          <Typography variant="button">
            <FormattedMessage
              id="button-text_view-cv"
              defaultMessage="View CV"
            />
          </Typography>
        </Button>
      </ResponsiveButtonGroup>
    </>
  );
};

export default CandidateCV;

import { ApplicationRejectionMessageType } from '../../components/Modal/RejectionReasonPreferenceModal/types';
import { ApplicationStatus } from '../constants';
import { userName } from '../filters';
import { ChangeApplicationStatusPayloadWithName } from '../hooks/requests/useBulkChangeApplicationStatus';
import { ApplicationDetails } from '../interfaces/entities';

type ApplicationData = {
  id: ApplicationDetails['id'];
  Applicant: Pick<
    ApplicationDetails['Applicant'],
    'firstName' | 'lastName' | 'email'
  >;
};

export const buildBulkChangeApplicationStatusPayload = (
  applications: ApplicationData[],
  incomingStatus: ApplicationStatus,
  selectedReasons?: ApplicationRejectionMessageType[],
  other?: string
) =>
  applications.map(application => ({
    applicationId: application.id,
    status: incomingStatus,
    name: userName(application.Applicant),
    ...(selectedReasons?.length && { rejectionReasons: selectedReasons }),
    ...(other && { otherReason: other }),
  })) as ChangeApplicationStatusPayloadWithName[];

/* eslint-disable camelcase */
import { TrackingService } from './tracking/trackingService';

type VIPExtendCtaClickedEventProperties = {
  event_location: 'purchased_tab' | 'features_tab' | 'expiring_popup';
};
export const trackVIPExtendCtaClicked = (
  properties: VIPExtendCtaClickedEventProperties
) => {
  TrackingService.logEvent('vip_extend_cta_clicked', properties);
};

export type VIPUpgradeToVIPCtaClickedEventProperties = {
  eventLocation:
    | 'reactivation_popup'
    | 'extension_popup'
    | 'expired_popup'
    | 'hide_salary'
    | 'make_resume_required'
    | 'make_education_level_required'
    | 'make_city_required'
    | 'make_profile_photo_required'
    | 'ats_wa'
    | 'app_popup'
    | 'app_popup_resume'
    | 'app_popup_attachments'
    | 'app_popup_contact'
    | 'app_popup_portfolio'
    | 'app_popup_links'
    | 'app_page'
    | 'app_page_resume'
    | 'app_page_attachments'
    | 'app_page_contact'
    | 'app_page_portfolio'
    | 'app_page_links'
    | 'chat_widget_wa'
    | 'chat_widget'
    | 'chat_widget_accept_cv'
    | 'chat_widget_exchange_cv'
    | 'chat_page_wa'
    | 'chat_page'
    | 'chat_page_contact'
    | 'chat_page_portfolio'
    | 'chat_page_links'
    | 'chat_page_resume'
    | 'chat_page_attachments'
    | 'chat_page_accept_cv'
    | 'chat_page_exchange_cv'
    | 'app_page_reject'
    | 'app_page_move_to'
    | 'app_popup_reject'
    | 'app_popup_move_to'
    | 'app_table_checkbox'
    | 'app_table_row_checkbox'
    | 'app_table_row_reject'
    | 'app_table_row_move_to'
    | 'chat_widget_move_to'
    | 'chat_page_move_to'
    | 'chat_widget_reject'
    | 'chat_page_reject'
    | 'reopen_competitive_job'
    | 'extend_competitive_job'
    | 'post_competitive_job';
};

export function trackVIPUpgradeCtaClicked({
  eventLocation,
}: VIPUpgradeToVIPCtaClickedEventProperties) {
  TrackingService.logEvent('vip_upgrade_to_vip_cta_clicked', {
    event_location: eventLocation,
  });
}

export const trackCVViewedEvent = (properties: {
  jobId: string;
  companyId: string;
  messageId?: string;
  channelId?: string;
  channelMembersCount?: number;
  applicationStatus?: string;
  messageSentDatetime?: string;
  actionType?: string;
}) => {
  const transformedProperties = {
    job_id: properties.jobId,
    message_id: properties.messageId,
    company_id: properties.companyId,
    channel_id: properties.channelId,
    channel_members_count: properties.channelMembersCount,
    application_status: properties.applicationStatus,
    message_sent_datetime: properties.messageSentDatetime,
    action_type: properties.actionType,
  };

  TrackingService.logEvent('CV Viewed', transformedProperties);
};

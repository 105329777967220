import { apm } from '@elastic/apm-rum';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import WebSocket from 'isomorphic-ws';

import axios from '../../../common/axios-ts';
import { GlintsChatChannelExchangeRequest } from '../types/exchange-request';
import {
  BASE_PATH,
  EMPLOYER_BASE_PATH,
  QUERY_CHANNELS_DEFAULT_INITIAL_PAGE,
  QUERY_CHANNELS_DEFAULT_LAST_CHANNEL_UPDATED_AT,
  QUERY_CHANNELS_DEFAULT_PAGE_SIZE,
  QUERY_MESSAGES_DEFAULT_LIMIT,
} from './consts';
import { GlintsChatWSConnection } from './GlintsChatWSConnection';
import {
  MessageReadInput,
  MessageReadResponse,
  QueryChannelResponse,
  QueryChannelsParams,
  QueryChannelsResponse,
  QueryMessagesParams,
  QueryMessagesResponse,
  SendableMessageContent,
  SendTextMessageResponse,
  UpdateMessageParams,
} from './types';
import {
  GlintsChatWSEvent,
  WSEventHandler,
  WSEventOperation,
} from './ws-event';

export class GlintsChatClient {
  private static _instance: GlintsChatClient | null;
  private token: string;
  private companyId: string;
  private listeners: Partial<
    Record<WSEventOperation, Array<WSEventHandler> | undefined>
  >;
  private wsConnection: GlintsChatWSConnection | null;

  constructor(token: string, companyId: string) {
    this.token = token;
    this.companyId = companyId;
    this.listeners = {};
    this.wsConnection = null;
  }

  private _setToken(token: string) {
    this.token = token;
  }

  private _setCompanyId(companyId: string) {
    this.companyId = companyId;
  }

  public static getInstance(token: string, companyId: string) {
    if (!this._instance) {
      this._instance = new GlintsChatClient(token, companyId);
    }

    const currentToken = this._instance.token;
    const currentCompanyId = this._instance.companyId;

    if (currentToken !== token) {
      this._instance._setToken(token);
    }

    if (currentCompanyId !== companyId) {
      this._instance._setCompanyId(companyId);
    }

    return this._instance;
  }

  private async doAxiosRequest<T>(
    type: 'get' | 'post' | 'put',
    path: string,
    data?: unknown,
    options: AxiosRequestConfig = {}
  ): Promise<T> {
    const axiosInstance = axios(this.token);
    try {
      let response: AxiosResponse<T>;

      switch (type) {
        case 'get':
          response = await axiosInstance.get<T>(path, options);
          break;
        case 'post':
          response = await axiosInstance.post<T>(path, data, options);
          break;
        case 'put':
          response = await axiosInstance.put<T>(path, data, options);
          break;
        default:
          throw new Error('Invalid request type');
      }

      return response.data;
    } catch (error) {
      apm.captureError(error as Error);
      throw error;
    }
  }

  private get<T>(url: string): Promise<T> {
    return this.doAxiosRequest<T>('get', url);
  }

  private post<T>(
    url: string,
    data: unknown,
    options: AxiosRequestConfig = {}
  ): Promise<T> {
    return this.doAxiosRequest<T>('post', url, data, options);
  }
  private put<Response, Variables = unknown>(
    url: string,
    data: Variables,
    options: AxiosRequestConfig = {}
  ): Promise<Response> {
    return this.doAxiosRequest<Response>('put', url, data, options);
  }

  getToken() {
    return this.token;
  }

  queryChannel(channelId: string) {
    return this.get<QueryChannelResponse>(`${BASE_PATH}/channel/${channelId}`);
  }

  queryChannels(input?: QueryChannelsParams) {
    const {
      lastChannelUpdatedAt = QUERY_CHANNELS_DEFAULT_LAST_CHANNEL_UPDATED_AT,
      page = QUERY_CHANNELS_DEFAULT_INITIAL_PAGE,
      pageSize = QUERY_CHANNELS_DEFAULT_PAGE_SIZE,
      jobID,
      query,
      hasUnread,
      sources,
      applicationStatus,
    } = input || {};

    const params = new URLSearchParams({
      lastChannelUpdatedAt: lastChannelUpdatedAt.toString(),
      companyID: this.companyId,
      page: page.toString(),
      pageSize: pageSize.toString(),
      ...(jobID && { jobID }),
      ...(query && { query }),
      ...(hasUnread && { hasUnread: String(true) }),
      ...(applicationStatus && { applicationStatus: applicationStatus }),
    });

    if (sources) {
      sources.forEach(source => params.append('sources', source));
    }

    return this.get<QueryChannelsResponse>(
      `${EMPLOYER_BASE_PATH}/channels?${params.toString()}`
    );
  }

  queryMessages(input: QueryMessagesParams) {
    const {
      channelId,
      limit = QUERY_MESSAGES_DEFAULT_LIMIT,
      lastMessageCreatedAt,
      lastMessageID,
    } = input;

    const params = new URLSearchParams({
      channelID: channelId,
      limit: limit.toString(),
      ...(lastMessageCreatedAt && {
        lastMessageCreatedAt: lastMessageCreatedAt.toString(),
      }),
      ...(lastMessageID && { lastMessageID }),
    });

    return this.get<QueryMessagesResponse>(
      `${BASE_PATH}/messages?${params.toString()}`
    );
  }

  sendMessage(
    channelId: string,
    messageContent: SendableMessageContent,
    options?: Omit<AxiosRequestConfig, 'data'>
  ) {
    return this.post<SendTextMessageResponse>(
      `${BASE_PATH}/message`,
      {
        channelID: channelId,
        ...messageContent,
      },
      options
    );
  }

  updateMessage(params: UpdateMessageParams) {
    return this.put<void>(`${BASE_PATH}/message`, params);
  }

  private updateChannel(
    channelId: string,
    exchangeRequests?: GlintsChatChannelExchangeRequest
  ) {
    return this.put(`${BASE_PATH}/channel`, {
      channelID: channelId,
      exchangeRequests,
    });
  }

  updateChannelExchangeRequest(
    channelId: string,
    exchangeRequests: GlintsChatChannelExchangeRequest
  ) {
    return this.updateChannel(channelId, exchangeRequests);
  }

  markMessageAsRead(
    input: MessageReadInput,
    options?: Omit<AxiosRequestConfig, 'data'>
  ) {
    return this.post<MessageReadResponse>(
      `${BASE_PATH}/message/read`,
      {
        channelID: input.channelId,
        messageID: input.messageId,
      },
      options
    );
  }

  getOrCreateChannel(applicationID: string) {
    return this.post<QueryChannelResponse>(`${BASE_PATH}/channel/start`, {
      applicationID,
    });
  }

  async connect() {
    // if there's already a healthy connection, return
    if (this.wsConnection && this.wsConnection.isHealthy) {
      return Promise.resolve();
    }

    this.wsConnection?.destroyCurrentWSConnection();

    this.wsConnection = new GlintsChatWSConnection(this);

    try {
      await this.wsConnection.connect();
    } catch (error) {
      apm.captureError(error as Error);

      // check if it's WS failure
      if (!this.wsConnection.isHealthy) {
        this.wsConnection.destroyCurrentWSConnection();
        this.wsConnection.disconnect(); // close WS so no retry
      }

      throw error;
    }
  }

  disconnect() {
    this.wsConnection?.disconnect();
  }

  on(
    operation: WSEventOperation,
    callback: WSEventHandler
  ): { unsubscribe: () => void } {
    if (!(operation in this.listeners)) {
      this.listeners[operation] = [];
    }

    this.listeners[operation]?.push(callback);

    return {
      unsubscribe: () => {
        this.listeners[operation] = this.listeners[operation]?.filter(
          listener => listener !== callback
        );
      },
    };
  }

  off(operation: WSEventOperation, callback: WSEventHandler) {
    this.listeners[operation] = this.listeners[operation]?.filter(
      listener => listener !== callback
    );
  }

  dispatchEvent = (event: GlintsChatWSEvent) => {
    const listeners = this.listeners[event.operation];
    if (listeners) {
      listeners.forEach(listener => listener(event));
    }
  };

  handleEvent = (messageEvent: WebSocket.MessageEvent) => {
    // dispatch the event to the channel listeners
    const jsonString = messageEvent.data as string;
    const event = JSON.parse(jsonString) as GlintsChatWSEvent;
    this.dispatchEvent(event);
  };
}

import { CHAT_ENABLED_COUNTRIES } from '../../common/constants';
import { getConfig } from '../../config';
import { getIsFeatureEnabled } from '../../selectors/features';
import { RootState } from '../../store';
import { UnleashFeatureNames } from '../Unleash/featureNames';

export const getIsChatV2Enabled = (state: RootState) => {
  const { COUNTRY } = getConfig();
  const domainCountrySupported = CHAT_ENABLED_COUNTRIES.includes(COUNTRY);

  const isChatV2Enabled =
    getIsFeatureEnabled(state, UnleashFeatureNames.allUseChatV2) &&
    getIsFeatureEnabled(state, UnleashFeatureNames.glintsChatDeployed);

  return isChatV2Enabled && domainCountrySupported;
};

import React, { Suspense, useState } from 'react';
import { Button, ButtonProps, PopoverProps } from 'glints-aries/es/@next';

import { ApplicationStatus } from '../../common/constants';
import { buildBulkChangeApplicationStatusPayload } from '../../common/helpers/bulkChangeApplicationStatus';
import useBulkChangeApplicationStatus, {
  BulkChangeApplicationStatusResponse,
} from '../../common/hooks/requests/useBulkChangeApplicationStatus';
import { useUnverifiedCompanyRestrictionModal } from '../../common/hooks/useUnverifiedCompanyRestrictionModal/useUnverifiedCompanyRestrictionModal';
import { BulkApplicationStatusSource } from '../../common/interfaces/bulk-application-status-source';
import { ApplicationDetails } from '../../common/interfaces/entities';
import { restrictUnverifiedCompanyMessage } from '../../modules/EmployerATSV2/components/ApplicationTable/applicationTableMessages';
import ErrorBoundaryWithCrashReporting from '../Error/ErrorBoundaryWithCrashReporting';
import useGetApplicationRejectionMessagePreferences from '../Modal/RejectionReasonPreferenceModal/hooks/useGetApplicationRejectionMessagePreferences';
import {
  ApplicationRejectionMessagePreference,
  ApplicationRejectionMessageType,
} from '../Modal/RejectionReasonPreferenceModal/types';
import RejectApplicationButtonWrapper from './RejectApplicationButtonWrapper';

type RejectApplicationButtonProps = ButtonProps &
  Pick<PopoverProps, 'preferredPosition' | 'preferredAlignment'> & {
    jobId: string;
    selectedApplications: ApplicationDetails[];
    onRejectSuccess?: (response: BulkChangeApplicationStatusResponse) => void;
    showTooltip?: boolean;
    eagerUpdateChannel?: boolean;
    bulkApplicationStatusSource: BulkApplicationStatusSource;
  };

function RejectApplicationButtonComponent({
  jobId,
  selectedApplications,
  onRejectSuccess,
  children,
  preferredPosition,
  preferredAlignment,
  showTooltip,
  eagerUpdateChannel,
  bulkApplicationStatusSource,
  ...buttonProps
}: RejectApplicationButtonProps) {
  const [loading, setLoading] = useState(false);
  const { rejectionMessagePreferences } =
    useGetApplicationRejectionMessagePreferences({
      fetchPolicy: 'cache-first',
    });

  const bulkChangeApplicationStatus = useBulkChangeApplicationStatus({
    jobId,
    onSuccess: onRejectSuccess,
  });

  const { getOpenRestrictionModalFunc } = useUnverifiedCompanyRestrictionModal(
    restrictUnverifiedCompanyMessage
  );

  const openRestrictionModalFunc = getOpenRestrictionModalFunc();

  const handleRejectApplications = async (
    rejectionType?: ApplicationRejectionMessageType
  ) => {
    if (openRestrictionModalFunc) {
      openRestrictionModalFunc();
      return;
    }
    setLoading(true);
    await bulkChangeApplicationStatus(
      buildBulkChangeApplicationStatusPayload(
        selectedApplications,
        ApplicationStatus.REJECTED,
        rejectionType ? [rejectionType] : undefined
      ),
      bulkApplicationStatusSource,
      { eagerUpdateChannel }
    );
    setLoading(false);
  };

  const handleCTAClick = async (e: React.MouseEvent) => {
    e.stopPropagation();

    if (
      rejectionMessagePreferences.preferredType ===
      ApplicationRejectionMessagePreference.CUSTOM_MESSAGE
    ) {
      return;
    }

    await handleRejectApplications();
  };

  return (
    <RejectApplicationButtonWrapper
      onRejectApplications={handleRejectApplications}
      rejectionPreference={rejectionMessagePreferences.preferredType}
      rejectionMessages={rejectionMessagePreferences.rejectionMessages}
      showTooltip={showTooltip}
      preferredAlignment={preferredAlignment}
      preferredPosition={preferredPosition}
    >
      <Button onClick={handleCTAClick} loading={loading} {...buttonProps}>
        {children}
      </Button>
    </RejectApplicationButtonWrapper>
  );
}

function RejectApplicationCTALoading({ style }: RejectApplicationButtonProps) {
  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };
  return <Button loading={true} onClick={stopPropagation} style={style} />;
}

export default function RejectApplicationButton(
  props: RejectApplicationButtonProps
) {
  return (
    <ErrorBoundaryWithCrashReporting fallback={null}>
      <Suspense fallback={<RejectApplicationCTALoading {...props} />}>
        <RejectApplicationButtonComponent {...props} />
      </Suspense>
    </ErrorBoundaryWithCrashReporting>
  );
}
